@import url('https://fonts.googleapis.com/css?family=Montserrat');
/* @import url('https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/material-design-icons/3.0.1/iconfont/material-icons.min.css'); */

body{
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  /* background: linear-gradient(-130deg, #4db6ac, #006666); */
  background: linear-gradient(-130deg, #4db6ac, #006666);
  min-height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;

}

.category:hover img{
filter: grayscale(100%);
}

.category:hover .hover-1{
color: #474A4B;}

#root{
background-color: #006666;
background: linear-gradient(-130deg, #4db6ac, #006666);

background-attachment: fixed;
background-size: cover;
}

.btn-sp{
  border: 0;
  background: transparent;
  color: #006666;
  font-weight: bold;
  font-size: 2em;
}

.pad{
  margin-top: 1%;
  padding: 2em 1em;
}

.containerr{
  display: grid;
  justify-items: stretch;
  color: black;
  width: 100%;
  min-height: 100vh;
   max-width: 800px;
  margin:auto;

}
.capitalize{
   text-transform: capitalize;
}
.profile-box{
  background:  linear-gradient(45deg, #E0E0E0, #BFC9CA);
  display: grid;
  justify-items: center;
  padding: 1em;
}
.singup-box{
  display: grid;
}
.full{
  width: 100%;
}

.full-vw{
  width: 100vw;
}
.main{
  background:  linear-gradient(to top, #E0E0E0, #E5E7E9);
  height: 100%;

}

.bold{
  font-weight: bold;
}
.gray{
  color: gray !important;
}
.txt-sm{
  font-size: 1.1em;
}
.txt-md{
  font-size: 1.2em;
}
.txt-mdd{
  font-size: 1.4em;
}
a{
  color: #006666 !important;
}
.txt-lg{
  font-size: 2em;
}
.txt-xl{
font-size: 2.5em;
}

.bordered{
  border-radius: 5px !important;
  color:#006666;
  border: 2px solid #006666;
  background: transparent;
  font-weight: bold;
  width: 10em;
  height: 3em;
    max-width: 49%;
}

.bordered-blue{
  border-radius: 5px !important;
  color: white;
  border: 2px solid #006666;
  background: #006666;
  font-weight: bold;
  width: 10em;
  height: 3em;
    max-width: 49%;
}

img{
  max-width: 99%;
  max-height: 50em;
  display: block;
  margin: auto;
  border-radius: .2em;
}

.blue-txt{
  color: #2B547E;
  color: #006666!important;
}
.half{
  width: 50%;
}

.md{
width: 6em;
  height: 6em;
}
a{
  color: #006666;
 }
.blue-bg{
  background: #006666;
}

.material-icons{
  vertical-align: middle;
}

.grid{
  display: grid;
  place-self: center
}
.grid-2{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.br-50{
  border-radius: 50%;
  border: 1px solid #009688;
  padding: 0;
  font-size: 110%;
}
.start{
  justify-self: start;
}
select{
  width: 6em !important;
}
.end{
  justify-self: end;
}
.align-end{
    align-self: end;
}

.center{

   justify-self: center;
  margin: auto;
  text-align: center;
}
.pointer{
  cursor: pointer;
}

.stretch{
  justify-self: stretch;
}

.v-center{
   vertical-align: middle;
   align-self: center;
}

.white-txt{
  color: white;
}

.hover:hover{
  background: #00897b ;
  color: white !important;
}
.hoverr:hover{
  background:#00897b ;
  color: white;
}

.disabled{
  color: gray;
}

.btn:hover{
  background: teal;
  color: white;
}


@media(max-width: 600px){
  body{
    font-size: 14px;
  }
  .hide-on-sm{
    display: none;
  }

}
@media(max-width: 400px){
.sp-2-sm{
    grid-column: 2/ span 2;
    justify-self: stretch;
  }

}
