@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Anton|Montserrat);
/* @import url('https://cdnjs.cloudflare.com/ajax/libs/materialize/1.0.0/css/materialize.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/material-design-icons/3.0.1/iconfont/material-icons.min.css'); */

body{
  font-size: 16px;
  font-family: Montserrat, sans-serif;
  /* background: linear-gradient(-130deg, #4db6ac, #006666); */
  background: linear-gradient(-130deg, #4db6ac, #006666);
  min-height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;

}

.category:hover img{
-webkit-filter: grayscale(100%);
        filter: grayscale(100%);
}

.category:hover .hover-1{
color: #474A4B;}

#root{
background-color: #006666;
background: linear-gradient(-130deg, #4db6ac, #006666);

background-attachment: fixed;
background-size: cover;
}

.btn-sp{
  border: 0;
  background: transparent;
  color: #006666;
  font-weight: bold;
  font-size: 2em;
}

.pad{
  margin-top: 1%;
  padding: 2em 1em;
}

.containerr{
  display: grid;
  justify-items: stretch;
  color: black;
  width: 100%;
  min-height: 100vh;
   max-width: 800px;
  margin:auto;

}
.capitalize{
   text-transform: capitalize;
}
.profile-box{
  background:  linear-gradient(45deg, #E0E0E0, #BFC9CA);
  display: grid;
  justify-items: center;
  padding: 1em;
}
.singup-box{
  display: grid;
}
.full{
  width: 100%;
}

.full-vw{
  width: 100vw;
}
.main{
  background:  linear-gradient(to top, #E0E0E0, #E5E7E9);
  height: 100%;

}

.bold{
  font-weight: bold;
}
.gray{
  color: gray !important;
}
.txt-sm{
  font-size: 1.1em;
}
.txt-md{
  font-size: 1.2em;
}
.txt-mdd{
  font-size: 1.4em;
}
a{
  color: #006666 !important;
}
.txt-lg{
  font-size: 2em;
}
.txt-xl{
font-size: 2.5em;
}

.bordered{
  border-radius: 5px !important;
  color:#006666;
  border: 2px solid #006666;
  background: transparent;
  font-weight: bold;
  width: 10em;
  height: 3em;
    max-width: 49%;
}

.bordered-blue{
  border-radius: 5px !important;
  color: white;
  border: 2px solid #006666;
  background: #006666;
  font-weight: bold;
  width: 10em;
  height: 3em;
    max-width: 49%;
}

img{
  max-width: 99%;
  max-height: 50em;
  display: block;
  margin: auto;
  border-radius: .2em;
}

.blue-txt{
  color: #2B547E;
  color: #006666!important;
}
.half{
  width: 50%;
}

.md{
width: 6em;
  height: 6em;
}
a{
  color: #006666;
 }
.blue-bg{
  background: #006666;
}

.material-icons{
  vertical-align: middle;
}

.grid{
  display: grid;
  place-self: center
}
.grid-2{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.br-50{
  border-radius: 50%;
  border: 1px solid #009688;
  padding: 0;
  font-size: 110%;
}
.start{
  justify-self: start;
}
select{
  width: 6em !important;
}
.end{
  justify-self: end;
}
.align-end{
    -webkit-align-self: end;
            align-self: end;
}

.center{

   justify-self: center;
  margin: auto;
  text-align: center;
}
.pointer{
  cursor: pointer;
}

.stretch{
  justify-self: stretch;
}

.v-center{
   vertical-align: middle;
   -webkit-align-self: center;
           align-self: center;
}

.white-txt{
  color: white;
}

.hover:hover{
  background: #00897b ;
  color: white !important;
}
.hoverr:hover{
  background:#00897b ;
  color: white;
}

.disabled{
  color: gray;
}

.btn:hover{
  background: teal;
  color: white;
}


@media(max-width: 600px){
  body{
    font-size: 14px;
  }
  .hide-on-sm{
    display: none;
  }

}
@media(max-width: 400px){
.sp-2-sm{
    grid-column: 2/ span 2;
    justify-self: stretch;
  }

}

body{
  font-size: 16px;
}
.center{
  text-align: center;
}

header{
  width: 100%;
}
.bold{
  font-weight: bold;
}
.txt-md{
  font-size: 1.2em;
}

.txt-lg{
  font-size: 2em;
}
.txt-xl{
font-size: 2.5em;
}

.bordered{
  border-radius: 5px;
  color:#006666;
  border: 2px solid #006666;
  background: transparent;
  font-weight: bold;
  width: 10em;
  height: 2.7em;
   max-width: 87vw;
}
.blue-txt{
  color: #2B547E;
  color: #006666;
}

.blue-bg{
  background: #006666;
}

.material-icons{
  vertical-align: middle;
}

.wrapper{
   position: absolute;
   min-height: 100vh;
   width: 100%;
   background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23f7f9f9'/%3E%3Cstop offset='1' stop-color='%23b2babb'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%23b2babb' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%23b2babb' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.4'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E");
   background-size: 100% 100%;
   background-size: cover;
 }


h3{
  background: -webkit-linear-gradient(-150deg, #4fc3f7, #006666);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-weight: 900;
line-height: 1;
font-size: 3em;
text-transform: uppercase;
color: #a483c5;
font-family: arial;
}

.stack{
 position: relative;
  z-index: 10;
}
.btn-floating{
  position: fixed;
  bottom: 1em;
  right: 1em;
}
.grid-start{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto auto;
  -webkit-justify-content: center;
          justify-content: center;
  grid-gap: 0px;
  min-height: 100vh;
}
.round{
  border-radius: 2em;
  color: white;
  border: 2px solid #006666;
  background: #006666;
  font-weight: bold;
  width: 10em;
  font-size: 1em;
  padding: 1em;
  max-width: 87vw;
}

.long{
  width: 30em;
  height: 4em;
  display: block;
  margin: 0.25em;
}
.bg-transparent{
  height: 260px;
  width: 150px;
/*   background-color: rgba(255, 255, 255, 0.12); */
  opacity: 0.88;
  display: grid;
  width: 100%;
}

.sm-logo{
  max-height: 12em;
  max-width: 12em;
}
.img{
  place-self: center;
  max-height: 40em;
  max-width: 40em;
}

.top{
 -webkit-align-self: start;
         align-self: start;
}
.center{
  -webkit-align-self: center;
          align-self: center;
}

.cardd{
  width: 150px;
  height: 280px;
  background-color: teal;
  opacity: 0.1;
  position: absolute;
  bottom: 0px;
  left: 0px;

}

.two{
  bottom: 50px;
  left: 120px;

}

.three{
  bottom: 100px;
  left: 240px;
}


@media(max-width: 1200px){

  .img{
    width: 25em;
  }

}

@media(max-width: 800px){
  body{
    font-size: 13px;
  }
  .img{
    width: 90%;
    height: auto;
  }
  .sm-logo{
    width: 12em;
    height: 12em;
  }
  h3{
    font-size: 2.2em;
  }

  .grid-start{
      grid-template-columns: 1fr;
  }

}

@media(max-width: 400px){
  body{
    font-size: 12px;
  }
   h3{
     font-size: 200%;
   }

}

